import {makeStyles} from '@mui/styles'

const useStyles = makeStyles(({spacing, layout, breakpoints}) => ({
  heading: {
    '& > div': {
      maxWidth: layout.heading.maxWidth,
      position: 'relative',
      zIndex: 2,
      margin: '0',
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.down('sm')]: {
        maxWidth: 'inherit',
      },
      '& > div:last-of-type': {
        [breakpoints.down('sm')]: {
          order: 1,
        },
      },
      '& > div:first-of-type': {
        [breakpoints.down('sm')]: {
          order: 2,
        },
      },
    },
    '& > div > div > p:last-of-type': {
      marginTop: spacing(1.25),
      marginBottom: 0,
    },
    '& > div > div > a:last-of-type': {
      marginTop: spacing(2.5),
      marginBottom: 0,
    },
  },
  centered: {
    display: 'flex',
    width: '100%',
    '& > div': {
      margin: 'auto',
    },
  },
  hasMargins: {
    margin: spacing(5, 0),
  },
  hasNoMaxWidth: {
    '& > div': {
      maxWidth: '100%',
    },
  },
}))

export default useStyles
